export default {
  en: {
    a2hs: 'Save Favourite',
    a2hs_body: 'Add this trail to Favourites for quick access at any time.',
    a2hs_share_1: 'Tap on "Share" in the menu at the bottom.',
    a2hs_share_2: 'Select "Add to Favourites".',
    about: 'About',
    accept_pp: 'I accept the <a href="{href}" target="_blank">Terms of Use &amp; Privacy Policy</a>',
    account: 'Account',
    admin: 'Admin',
    answer: 'Answer',
    answered_correct: 'Correctly answered',
    answered_wrong: 'Wrong answer given',
    ar_howto: 'Tap on the round button and place the object on a free area by moving your device back and forth. You can then walk around the object, look at it from all sides, move it (tap) or change its size (zoom gesture).',
    authenticated_as: 'Authenticated as:',
    back: 'Back',
    browser_hint_android: 'Please open this trail in Google Chrome to ensure optimal functionality.',
    browser_hint_ios: 'Please open this trail in Safari or in Google Chrome to ensure optimal functionality.',
    browser_no_ar_android: 'This browser currently doesn\'t fully support Augmented Reallity. Please use the Google Chrome browser to get the full Augmented Reallity experience.',
    browser_no_ar_ios: 'This browser currently doesn\'t fully support Augmented Reallity. Please use the Safari or Google Chrome browser to get the full Augmented Reallity experience.',
    buy_tour: 'Buy trail',
    cancel: 'Cancel',
    centerpos: 'Center',
    choice: 'Choice',
    close: 'Close',
    close_post: 'Close Post',
    continue: 'Continue',
    continue_started: 'Continue',
    correct_answer: 'Correct Answer',
    created_with: 'created with',
    created_with_2: 'implemented with <a href="https://tourify.ch/smartrails" target="_blank">smarTrails</a><sup>&reg;</sup> – a service of <a href="https://tourify.ch" target="_blank">Tourify</a>',
    email: 'E-mail',
    email_optional: 'E-mail (optional)',
    err_not_found: 'Not found :(',
    err_oops: 'Oops.. there is something wrong :(',
    err_provider_not_found: 'Provider not found :(',
    err_tour_not_found: 'Tour not found :(',
    field_required: 'This fiels is required',
    finish: 'Finish',
    game: 'Game',
    game_won: 'won',
    game_lost: 'lost',
    game_instructions: 'Game instructions',
    game_instructions_imagepairs: 'Which pictures belong together? Tap on one of the pictures listed below and then on the corresponding field.',
    game_instructions_falldown: 'If the image that falls down corresponds to [CRITERIA REPLACE HERE], then press the credit sign - otherwise press the cross. For each hit you get a smiley, each mistake costs a heart.',
    game_instructions_hangman: 'Tap on the letters that you think appear in the word you are looking for. For every hit you get a smiley, every miss costs a heart.',
    game_resolution_hangman: 'Solution',
    games: 'Games',
    geoerr: 'Position error',
    geoerr_1: 'This app requires access to the current position, please allow access in the settings. For more information, see help.',
    geoerr_2: 'Position unavailable',
    geoerr_3: 'Position timeout',
    geohelp: 'Do you need help?',
    geohelp_reach: 'I can\'t reach the Post',
    geohelp_find: 'I can\'t find the Post',
    geohelp_other: 'Other reason',
    goto_first_post: 'Go to the first post to start!',
    goto_next_post: 'Go to the next post to continue',
    goto_provider: 'See all our trails',
    help: 'Help',
    help_center: 'Help Center',
    help_hint: 'How to play',
    help_open: 'Open Help',
    imprint: 'Imprint & Privacy Policy',
    lang: 'Language',
    langs: 'Languages',
    login_as_admin: 'Login with your Admin Account',
    lost: 'lost',
    not_now: 'Not now',
    number: 'Number',
    made_by: 'Offered by',
    made_by_2: 'This is an offer from',
    map: 'Map',
    offline_form: 'You\'re offline - you can submit this form as soon as you\'re back online.',
    offline_game: 'You\'re offline - you can play this game as soon as you\'re back online.',
    offline_quiz: 'You\'re offline - you can answer this question as soon as you\'re back online.',
    offline_smartvue: 'You\'re offline - you can watch this object as soon as you\'re back online.',
    offline_video: 'You\'re offline - you can watch this video as soon as you\'re back online.',
    open_post: 'Open Post',
    open_post_manual: 'Open Post manually',
    overview: 'Overview',
    payment: 'Payment',
    payment_success: 'Payment successful - Thank you!',
    payment_failed: 'Payment failed!',
    payment_cancel: 'Payment cancelled',
    pois: 'Points of Interest', // TODO
    post: 'Post',
    post_unlocked: 'Post unlocked!',
    posts: 'Posts',
    posts_including: 'Posts (up to and including)',
    progress: 'Progress',
    progress_tour: 'Tour (Posts visited)',
    providers: 'Providers',
    qrcode: 'QR Code',
    qrcode_invalid: 'QR Code invalid',
    qrcode_unlock: 'Search and scan the QR Code to unlock this content',
    qrhelp: 'Problems scanning QR Code?',
    qrhelp_text: 'Does scanning the QR code not work? Then try to read the QR code with the regular camera app of your smartphone or with an QR code scanner app.',
    qrscan: 'Scan QR Code',
    question: 'Question',
    quiz: 'Quiz',
    quizzes: 'Quizzes',
    rate: 'Rate this trail',
    rating: 'Rating:',
    recover: 'Unlock posts',
    recover_info: 'Please enter the code you receive from the support hotline:',
    recover_code_invalid: 'Code invalid',
    recover_done: 'Posts unlocked',
    report_problem: 'Report Problem',
    retry: 'Retry',
    review: 'Review:',
    reset_games: 'Reset Games', // TODO
    reset_quizzes: 'Reset Quizzes',
    restart: 'Restart',
    restart_confirm: 'Reset all visited posts and delete all quiz answers?',
    right: 'right',
    roadmap: 'Roadmap',
    safari_private_warning: 'Hint: If you\'re using Safari in Private mode, Payments and saving your data might fail.',
    satellite: 'Satellite',
    searching: 'Searching position..',
    searching_bad: 'Are location services enabled? For more information, see help.',
    score: 'Score',
    set_all_posts_visited: 'All Posts = visited',
    set_all_posts_unvisited: 'All Posts = unvisited',
    share: 'Share',
    sign_guest: 'Start without account',
    sign_in: 'Sign in',
    sign_in_promo: 'Sign in recommended',
    sign_in_promo_text: 'A personal user account offers several advantages',
    sign_in_promo_learnmore: 'Learn more',
    sign_in_recommended: 'Sign in recommended',
    sign_in_recommended_voucher_info: 'By logging in, the voucher will remain stored in your account.',
    sign_in_required: 'Sign in required',
    sign_in_required_info: 'In order to start the trail, you have to register first.',
    sign_in_required_payment_info: 'In order to pay the trail and start it, you have to register first.',
    sign_in_with: 'Sign in with',
    sign_out: 'Sign out',
    start: 'Start',
    submit: 'Submit',
    support: 'Support',
    support_contact: 'Get in touch',
    selfservice: 'Self-Service',
    selfservice_text: 'Unlock posts when there are problems and you get stuck.',
    selfservice_unlock_posts: 'Unlock Posts',
    selfservice_unlock_posts_reason: 'Please briefly explain the issue so that we can check the trail and fix any errors.',
    tel: 'Telephone',
    thankyou: 'Thank you!',
    timer: 'Timer',
    timer_countup: 'Time used',
    timer_countdown: 'Countdown',
    timer_expired: 'Time expired',
    timer_time: 'Time limit',
    tour: 'Trail',
    tour_closed: 'This Trail is currently closed.',
    tours: 'Trails',
    tries_left: 'Tries left: {triesLeft}',
    unauthenticated: 'Not authenticated',
    unauthenticated_title: 'You are currently using the smarTrails app without a personal user account.',
    unauthenticated_text: 'A personal account is free of charge and offers you various advantages. For example, your progress (posts visited, puzzle questions answered, etc.) is saved across devices.',
    use_your_smartphone: 'Start this trail on your Smartphone.',
    visit_first: 'Visit the post first to see it\'s content!',
    visit_posts_in_order: 'Visit posts in given order!',
    visited: 'visited',
    voucher_code: 'Voucher Code',
    voucher_redeem: 'Redeem Voucher',
    voucher_err_notfound: 'Code invalid / not found!',
    voucher_err_used: 'Code has already been redeemed by another user!',
    voucher_err_unknown: 'Code cannot be redeemed (unknown error)!',
    won: 'won',
    wrong: 'wrong',
    en: 'English',
    de: 'German',
    fr: 'French',
    it: 'Italian',
    rm: 'Romansh',
    es: 'Spanish',
    ja: 'Japanese',
    'tag_trail-loopTour': 'loop',
    'tag_trail-scenic': 'scenic',
    'tag_general-suitableforfamilies': 'family friendly',
    'tag_general-dogFriendly': 'dog friendly',
    'tag_accessibility-suitableforperambulators': 'suitable for strollers',
    'tag_transport-publicTransportFriendly': 'accessible by public transport',
    'tag_general-educational': 'educational',
    'tag_incentive-quiz': 'with puzzles',
    'tag_incentive-reward': 'with reward at the end',
    'tag_general-playground': 'playground along the way',
    'tag_general-fireplace': 'fire pit along the way',
    'tag_general-dining': 'refreshment stops available',
    'tag_general-shopping': 'shopping options available',
    'tag_general-publictoilet': 'public toilet available',
    'tag_accessibility-wheelchair-accessible': 'disability access',
  },
  de: {
    a2hs: 'Favorit speichern',
    a2hs_body: 'Speichern Sie diesen Trail als Favoriten, um jederzeit rasch darauf zugreifen zu können.',
    a2hs_share_1: 'Tippen Sie unten im Menü auf "Teilen".',
    a2hs_share_2: 'Wählen Sie "Als Favoriten sichern".',
    about: 'About',
    accept_pp: 'Ich akzeptiere die <a href="{href}" target="_blank">Nutzungsbedingungen &amp; Datenschutzerklärung</a>',
    account: 'Account',
    admin: 'Admin',
    answer: 'Antwort',
    answered_correct: 'Richtig beantwortet',
    answered_wrong: 'Falsch beantwortet',
    ar_howto: 'Tippen Sie auf den runden Button und platzieren Sie das Objekt auf einer freien Fläche, indem Sie Ihr Gerät hin- und herbewegen. Anschliessend können Sie um das Objekt herumlaufen, es von allen Seiten betrachten, es verschieben (Antippen) oder seine Grösse ändern (Zoom-Geste).',
    authenticated_as: 'Angemeldet als:',
    back: 'Zurück',
    browser_hint_android: 'Bitte öffnen Sie diesen Trail in Google Chrome, um eine optimale Funktionalität sicherzustellen.',
    browser_hint_ios: 'Bitte öffnen Sie diesen Trail in Safari oder Google Chrome, um eine optimale Funktionalität sicherzustellen.',
    browser_no_ar_android: 'Dieser Browser unterstützt Augmented Reallity noch nicht vollumfänglich. Bitte verwenden Sie den Google Chrome Browser, um Augmented Reallity erleben zu können.',
    browser_no_ar_ios: 'Dieser Browser unterstützt Augmented Reallity noch nicht vollumfänglich. Bitte verwenden Sie den Safari oder Google Chrome Browser, um Augmented Reallity erleben zu können.',
    buy_tour: 'Trail kaufen',
    cancel: 'Abbrechen',
    centerpos: 'Zentrieren',
    choice: 'Auswahl',
    close: 'Schliessen',
    close_post: 'Posten schliessen',
    continue: 'Weiter',
    continue_started: 'Fortsetzen',
    correct_answer: 'Richtige Antwort',
    created_with: 'umgesetzt mit',
    created_with_2: 'umgesetzt mit <a href="https://tourify.ch/smartrails" target="_blank">smarTrails</a><sup>&reg;</sup> – einem Service von <a href="https://tourify.ch" target="_blank">Tourify</a>',
    email: 'E-Mail',
    email_optional: 'E-Mail (optional)',
    err_not_found: 'Nicht gefunden :(',
    err_oops: 'Oops.. etwas ist schief gegangen :(',
    err_provider_not_found: 'Provider nicht gefunden :(',
    err_tour_not_found: 'Tour nicht gefunden :(',
    field_required: 'Muss ausgefüllt werden',
    finish: 'Ziel',
    game: 'Game',
    game_won: 'gewonnen',
    game_lost: 'verloren',
    game_instructions: 'Spielanleitung',
    game_instructions_imagepairs: 'Welche Bilder gehören zusammen? Tippen Sie auf eines der unten aufgereihten Bilder und anschliessend auf das entsprechende Feld.',
    game_instructions_falldown: 'Entspricht das herunterfallende Bild [KRITERIEN HIER ERGÄNZEN], dann drücken Sie auf das Gutzeichen – anderenfalls auf das Kreuz. Für jeden Treffer gibts ein Smiley, jeder Fehler kostet ein Herzchen.',
    game_instructions_hangman: 'Tippen Sie auf die Buchstaben, von denen Sie glauben, dass sie im gesuchten Wort vorkommen. Für jeden Treffer gibts ein Smiley, jeder Fehlgriff kostet ein Herzchen.',
    game_resolution_hangman: 'Lösung',
    games: 'Games',
    geoerr: 'Positionsfehler',
    geoerr_1: 'Diese App benötigt Zugriff auf die aktuelle Position. Bitte gewähren Sie diesen in den Einstellungen. Weitere Informationen finden Sie in der Hilfe.',
    geoerr_2: 'Position nicht verfügbar',
    geoerr_3: 'Position timeout',
    geohelp: 'Brauchen Sie Hilfe?',
    geohelp_reach: 'Ich komme nicht bis an den Posten heran',
    geohelp_find: 'Ich kann den Posten nicht finden',
    geohelp_other: 'Anderer Grund',
    goto_first_post: 'Gehen Sie zum ersten Posten.',
    goto_next_post: 'Gehen Sie zum nächsten Posten.',
    goto_provider: 'Alle Trails ansehen',
    help: 'Hilfe',
    help_hint: 'Spielanleitung',
    help_open: 'Hilfe öffnen',
    imprint: 'Impressum & Datenschutz',
    lang: 'Sprache',
    langs: 'Sprachen',
    login_as_admin: 'Melden Sie sich mit Ihrem Admin Account an.',
    not_now: 'Nicht jetzt',
    lost: 'verloren',
    number: 'Nummer',
    made_by: 'Ein Angebot von',
    made_by_2: 'Dies ist ein Angebot von',
    map: 'Karte',
    offline_form: 'Zurzeit besteht keine Internetverbindung. Sie können dieses Formular absenden, sobald Sie wieder Empfang haben.',
    offline_game: 'Zurzeit besteht keine Internetverbindung. Sie können dieses Game spielen, sobald Sie wieder Empfang haben.',
    offline_quiz: 'Zurzeit besteht keine Internetverbindung. Sie können diese Frage beantworten, sobald Sie wieder Empfang haben.',
    offline_smartvue: 'Zurzeit besteht keine Internetverbindung. Sie können sich dieses Objekt ansehen, sobald Sie wieder Empfang haben.',
    offline_video: 'Zurzeit besteht keine Internetverbindung. Sie können sich dieses Video ansehen, sobald Sie wieder Empfang haben.',
    open_post: 'Posten öffnen',
    open_post_manual: 'Posten manuell öffnen',
    overview: 'Übersicht',
    payment: 'Bezahlung',
    payment_success: 'Bezahlung erfolgreich - Vielen Dank!',
    payment_failed: 'Bezahlung fehlgeschlagen!',
    payment_cancel: 'Bezahlung abgebrochen',
    post: 'Posten',
    post_unlocked: 'Posten freigeschaltet!',
    posts: 'Posten',
    posts_including: 'Posten (bis und mit)',
    progress: 'Fortschritt',
    progress_tour: 'Tour (Posten besucht)',
    providers: 'Anbieter',
    qrcode: 'QR Code',
    qrcode_invalid: 'QR Code ungültig',
    qrcode_unlock: 'Suchen und scannen Sie den QR Code, um den Inhalt dieses Postens freizuschalten.',
    qrhelp: 'Probleme beim Scannen?',
    qrhelp_text: 'Funktioniert das Scannen des QR-Codes nicht? Dann versuchen Sie, den QR-Code mit der normalen Kamera-App Ihres Smartphones oder mit einer QR-Code Scanner App zu lesen.',
    qrscan: 'QR Code scannen',
    question: 'Frage',
    quiz: 'Quiz',
    quizzes: 'Quizze',
    rate: 'Trail bewerten',
    rating: 'Bewertung',
    recover: 'Posten freischalten',
    recover_info: 'Bitte geben Sie den Code ein, den Sie von der Support-Hotline erhalten:',
    recover_code_invalid: 'Code ungültig',
    recover_done: 'Posten freigeschaltet',
    report_problem: 'Problem melden',
    retry: 'Wiederholen',
    review: 'Bemerkungen',
    reset_games: 'Games resetten',
    reset_quizzes: 'Quizze resetten',
    restart: 'Neu beginnen',
    restart_confirm: 'Sind Sie sicher? Dadurch müssen Sie alle bereits besuchten Posten nochmals besuchen.',
    right: 'richtig',
    roadmap: 'Karte',
    safari_private_warning: 'Hinweis: Falls Sie Safari im Privat-Modus verwenden, können Zahlungen und andere Funktionen fehlschlagen.',
    satellite: 'Satellit',
    searching: 'Position wird gesucht..',
    searching_bad: 'Haben Sie die Ortungsdienste aktiviert? Weitere Informationen finden Sie in der Hilfe.',
    score: 'Erreichte Punktzahl',
    set_all_posts_visited: 'Alle Posten = besucht',
    set_all_posts_unvisited: 'Alle Posten = unbesucht',
    share: 'Teilen',
    sign_guest: 'ohne Account starten',
    sign_in: 'Anmelden',
    sign_in_promo: 'Anmeldung empfohlen',
    sign_in_promo_text: 'Ein persönlicher Benutzer-Account bietet verschiedene Vorteile.',
    sign_in_promo_learnmore: 'mehr erfahren',
    sign_in_recommended: 'Anmeldung empfohlen',
    sign_in_recommended_voucher_info: 'Durch eine Anmeldung bleibt der Gutschein in Ihrem Konto gespeichert.',
    sign_in_required: 'Anmeldung erforderlich',
    sign_in_required_info: 'Um den Trail starten zu können, müssen Sie sich zuerst anmelden.',
    sign_in_required_payment_info: 'Um den Trail bezahlen und starten zu können, müssen Sie sich zuerst anmelden.',
    sign_in_with: 'Anmelden mit',
    sign_out: 'Abmelden',
    start: 'Starten',
    submit: 'Absenden',
    support_contact: 'So erreichen Sie uns',
    selfservice: 'Self-Service',
    selfservice_text: 'Schalten Sie Posten frei, wenn es Probleme gibt und Sie nicht weiterkommen.',
    selfservice_unlock_posts: 'Posten freischalten',
    selfservice_unlock_posts_reason: 'Bitte erläutern Sie kurz den Sachverhalt, damit wir den Trail überprüfen und eventuelle Fehler beheben können.',
    tel: 'Telefon',
    thankyou: 'Vielen Dank!',
    timer: 'Timer',
    timer_countup: 'Benötigte Zeit',
    timer_countdown: 'Countdown',
    timer_expired: 'Zeit abgelaufen',
    timer_time: 'Zeitlimit',
    tour: 'Trail',
    tour_closed: 'Der Trail ist zurzeit geschlossen.',
    tours: 'Trails',
    tries_left: 'Noch {triesLeft} Versuch(e) übrig!',
    unauthenticated: 'Nicht angemeldet',
    unauthenticated_title: 'Sie verwenden die smarTrails App zurzeit ohne persönlichen Benutzer-Account.',
    unauthenticated_text: 'Ein persönlicher Account ist kostenlos und bietet Ihnen verschiedene Vorteile. Beispielsweise wird Ihr Fortschritt (besuchte Posten, beantwortete Rätselfragen, etc.) geräteübergreifend gespeichert.',
    use_your_smartphone: 'Starten Sie diesen Trail auf Ihrem Smartphone und begeben Sie sich auf den Trail.',
    visit_first: 'Besuchen Sie zuerst den Posten, um seinen Inhalt sehen zu können!',
    visit_posts_in_order: 'Besuchen Sie die Posten in der vorgegebenen Reihenfolge!',
    visited: 'besucht',
    voucher_code: 'Gutschein-Code',
    voucher_redeem: 'Gutschein einlösen',
    voucher_err_notfound: 'Code ungültig / nicht gefunden!',
    voucher_err_used: 'Code wurde bereits (durch jemand anderen) eingelöst!',
    voucher_err_unknown: 'Code kann nicht eingelöst werden (unbekannter Fehler)!',
    wrong: 'falsch',
    won: 'gewonnen',
    en: 'Englisch',
    de: 'Deutsch',
    fr: 'Französisch',
    it: 'Italienisch',
    rm: 'Rätoromanisch',
    es: 'Spanisch',
    ja: 'Japanisch',
    'tag_trail-loopTour': 'Rundtour',
    'tag_trail-scenic': 'aussichtsreich',
    'tag_general-suitableforfamilies': 'familienfreundlich',
    'tag_general-dogFriendly': 'hundefreundlich',
    'tag_accessibility-suitableforperambulators': 'kinderwagengerecht',
    'tag_transport-publicTransportFriendly': 'mit dem ÖV erreichbar',
    'tag_general-educational': 'lehrreich',
    'tag_incentive-quiz': 'mit Rätsel',
    'tag_incentive-reward': 'mit Belohnung am Ende',
    'tag_general-playground': 'Spielplatz unterwegs',
    'tag_general-fireplace': 'Feuerstelle unterwegs',
    'tag_general-dining': 'Einkehrmöglichkeit unterwegs',
    'tag_general-shopping': 'Einkaufsmöglichkeit unterwegs',
    'tag_general-publictoilet': 'öffentliche Toilette unterwegs',
    'tag_accessibility-wheelchair-accessible': 'rollstuhlgängig',
  },
  fr: {
    a2hs: 'Sauvegarder favori',
    a2hs_body: 'Sauvegardez ce trail dans vos favoris pour pouvoir y accéder rapidement à tout moment.',
    a2hs_share_1: 'Tapez sur "Partager" dans le menu en bas.',
    a2hs_share_2: 'Sélectionnez "Sauvegarder comme favori".',
    about: 'À propos de',
    accept_pp: 'J\'accepte les <a href="{href}" target="_blank">Conditions d\'utilisation &amp; Déclaration de confidentialité</a>.',
    account: 'Compte',
    admin: 'Administrateur',
    answer: 'Réponse',
    answered_correct: 'Réponse correcte',
    answered_wrong: 'Réponse fausse',
    ar_howto: 'Tapez sur le bouton rond et placez l\'objet sur une surface libre en faisant un mouvement de va-et-vient avec votre appareil. Vous pouvez ensuite faire le tour de l\'objet, l\'observer sous tous les angles, le déplacer (taper) ou le redimensionner (geste de zoom).',
    authenticated_as: 'Authentifié / Connecté en tant que :',
    back: 'Retourner',
    browser_hint_android: 'Veuillez ouvrir ce trail dans Google Chrome pour garantir une fonctionnalité optimale.',
    browser_hint_ios: 'Veuillez ouvrir ce trail dans Safari ou Google Chrome afin d\'assurer une fonctionnalité optimale.',
    browser_no_ar_android: 'Ce navigateur n\'est pas encore entièrement compatible avec la réalité augmentée. Veuillez utiliser le navigateur Google Chrome pour profiter pleinement de la réalité augmentée.',
    browser_no_ar_ios: 'Ce navigateur n\'est pas encore entièrement compatible avec la réalité augmentée. Veuillez utiliser le navigateur Safari ou Google Chrome pour profiter pleinement de la réalité augmentée.',
    buy_tour: 'Acheter le trail',
    cancel: 'Annuler',
    centerpos: 'Centrer',
    choice: 'Choix',
    close: 'Fermer',
    close_post: 'Fermer le poste',
    continue: 'Continuer',
    continue_started: 'Continuer',
    correct_answer: 'Réponse correcte',
    created_with: 'crée avec',
    created_with_2: 'réalisé avec <a href="https://tourify.ch/smartrails" target="_blank">smarTrails</a><sup>&reg;</sup> – un service de <a href="https://tourify.ch" target="_blank">Tourify</a>',
    email: 'E-mail',
    email_optional: 'E-mail (facultatif)',
    err_not_found: 'Inaccessible :(',
    err_oops: 'Oups... Il y a un problème :(',
    err_provider_not_found: 'Fournisseur non trouvé :(',
    err_tour_not_found: 'Circuit non trouvé :(',
    field_required: 'Doit être rempli',
    finish: 'Fin',
    game: 'Game',
    game_won: 'gagné',
    game_lost: 'perdu',
    game_instructions: 'Instructions',
    game_instructions_imagepairs: 'Quelles images vont ensemble ? Tapez sur l\'une des images listées ci-dessous, puis sur le champ correspondant.',
    game_instructions_falldown: 'Si l\'image qui tombe correspond à [REMPLACER LES CRITÈRES ICI], appuyez sur le signe "Bien" - sinon, appuyez sur la croix. Chaque réponse positive est accompagnée d\'un smiley, chaque erreur coûte un petit cœur.',
    game_instructions_hangman: 'Tapez sur les lettres qui, selon vous, se trouvent dans le mot recherché. Pour chaque réponse positive, vous recevez un smiley, pour chaque réponse négative, vous recevez un petit cœur.',
    game_resolution_hangman: 'Solution',
    games: 'Games',
    geoerr: 'Erreur de positionnement',
    geoerr_1: 'Cette application nécessite un accès à la position actuelle, veuillez autoriser cet accès dans les paramètres. Pour plus d\'informations, consultez l\'aide.',
    geoerr_2: 'Position non disponible',
    geoerr_3: 'L\'emplacement n\'a pas pu être déterminé',
    geohelp: 'Besoin d\'aide ?',
    geohelp_reach: 'Je n\'arrive pas a accéder le poste',
    geohelp_find: 'Je ne trouve pas le poste',
    geohelp_other: 'Autre raison',
    goto_first_post: 'Allez au premièr poste pour commencer !',
    goto_next_post: 'Allez au prochaine poste.',
    goto_provider: 'Voir toutes les trails',
    help: 'Aide',
    help_hint: 'Les règles du jeu',
    help_open: 'Ouvrir l\'aide',
    imprint: 'Mentions légales & protection des données',
    lang: 'Langue',
    langs: 'Langues',
    login_as_admin: 'Connectez-vous avec votre compte administrateur.',
    lost: 'perdu',
    not_now: 'Pas maintenant',
    number: 'Numéro',
    made_by: 'Offert par',
    made_by_2: 'Ceci est une offre de',
    map: 'Carte',
    offline_form: 'Il n\'y a pas de connexion Internet pour le moment. Vous pourrez soumettre ce formulaire dès que vous serez à nouveau connecté.',
    offline_game: 'Il n\'y a pas de connexion Internet pour le moment. Vous pourrez jouer ce game dès que vous serez à nouveau connecté.',
    offline_quiz: 'Il n\'y a pas de connexion Internet pour le moment. Vous pourrez répondre à cette question dès que vous serez à nouveau connecté.',
    offline_smartvue: 'Il n\'y a pas de connexion Internet pour le moment. Vous pourrez voir cet objet dès que vous serez à nouveau connecté.',
    offline_video: 'Il n\'y a pas de connexion Internet pour le moment. Vous pourrez regarder cette vidéo dès que vous serez à nouveau connecté.',
    open_post: 'Accéder le poste',
    open_post_manual: 'Accéder le poste manuellement',
    overview: 'Aperçu',
    payment: 'Paiement',
    payment_success: 'Paiement réussi - Merci beaucoup !',
    payment_failed: 'Échec du paiement !',
    payment_cancel: 'Paiement annulé',
    post: 'Poste',
    post_unlocked: 'Poste débloqué !',
    posts: 'Postes',
    posts_including: 'Postes (jusqu\'a et y compris)',
    progress: 'Progression',
    progress_tour: 'Circuit (postes visités)',
    providers: 'Fournisseurs',
    qrcode: 'QR code',
    qrcode_invalid: 'QR code non valide',
    qrcode_unlock: 'Cherchez et scannez le QR code pour débloquer le contenu de ce poste.',
    qrhelp: 'Problèmes de scannage du QR code ?',
    qrhelp_text: 'Le scannage du QR code ne fonctionne pas ? Essayez alors de lire le QR code avec l\'application normale de l\'appareil photo de votre smartphone ou avec une application de scanner de QR code.',
    qrscan: 'Scanner le QR code',
    question: 'Question',
    quiz: 'Quiz',
    quizzes: 'Quiz',
    rate: 'Évaluez ce trail',
    rating: 'Évaluation',
    recover: 'Débloquer des postes',
    recover_info: 'Veuillez saisir le code que vous recevrez de la hotline du support:',
    recover_code_invalid: 'Code non valide',
    recover_done: 'Postes ont été déverrouillées',
    report_problem: 'Signaler un problème',
    retry: 'Répéter',
    review: 'Remarques',
    reset_games: 'Réinitialiser les games',
    reset_quizzes: 'Réinitialiser les quiz',
    restart: 'Redémarrer',
    restart_confirm: 'Êtes-vous sûr ? Cela vous oblige à visiter à nouveau tous les postes que vous avez déjà visitées.',
    right: 'correct',
    roadmap: 'Carte (routière)',
    safari_private_warning: 'Indication : si vous utilisez Safari en mode privé, les paiements et autres fonctions peuvent échouer.',
    satellite: 'Satellite',
    searching: 'Recherche de position…',
    searching_bad: 'Avez-vous activé les services de localisation ? Pour plus d\'informations, consultez l\'aide.',
    score: 'Score obtenu / nombre de points obtenus',
    set_all_posts_visited: 'Touts les postes = visitées',
    set_all_posts_unvisited: 'Touts les postes = non visitées',
    share: 'Partager',
    sign_guest: 'Démarrer sans compte',
    sign_in: 'Se connecter',
    sign_in_promo: 'Connexion recommandée',
    sign_in_promo_text: 'Un compte d\'utilisateur personnel offre différents avantages.',
    sign_in_promo_learnmore: 'En savoir plus',
    sign_in_recommended: 'Connexion recommandée',
    sign_in_recommended_voucher_info: 'En vous connectant, le bon d\'achat reste enregistré dans votre compte.',
    sign_in_required: 'Connexion nécessaire',
    sign_in_required_info: 'Pour pouvoir démarrer le trail, vous devez d\'abord vous connecter.',
    sign_in_required_payment_info: 'Pour pouvoir payer et démarrer le trail, vous devez d\'abord vous connecter.',
    sign_in_with: 'Se connecter avec',
    sign_out: 'Se déconnecter',
    start: 'Démarrer',
    submit: 'Soumettre',
    support: 'Support',
    support_contact: 'Comment nous contacter',
    selfservice: 'Self-Service',
    selfservice_text: 'Débloquez des postes en cas de problème et de blocage.',
    selfservice_unlock_posts: 'Déverrouiller postes',
    selfservice_unlock_posts_reason: 'Merci d\'expliquer brièvement la situation afin que nous puissions vérifier le trail et corriger les éventuelles erreurs.',
    tel: 'Téléphone',
    thankyou: 'Merci beaucoup !',
    timer: 'Timer',
    timer_countup: 'Temps utilisé',
    timer_countdown: 'Countdown',
    timer_expired: 'Temps écoulé',
    timer_time: 'Limite de temps',
    tour: 'Trail',
    tour_closed: 'Le trail est actuellement fermé.',
    tours: 'Trails',
    tries_left: 'Il reste encore {triesLeft} essais !',
    unauthenticated: 'Non connecté / authentifié',
    unauthenticated_title: 'Vous utilisez actuellement l\'application smarTrails sans compte d\'utilisateur personnel.',
    unauthenticated_text: 'Un compte d\'utilisateur personnel est gratuit et vous offre différents avantages. Par exemple, votre progression (postes visités, questions auxquelles vous avez répondu, etc.) est enregistrée sur tous les appareils.',
    use_your_smartphone: 'Démarrez ce trail sur votre smartphone et laissez-vous guider à travers lui.',
    visit_first: 'Visitez d\'abord le poste pour voir son contenu !',
    visit_posts_in_order: 'Visitez les postes dans l\'ordre indiqué !',
    visited: 'visités',
    voucher_code: 'Code du bon d’achat',
    voucher_redeem: 'Utiliser bon d’achat',
    voucher_err_notfound: 'Code invalide / non trouvé !',
    voucher_err_used: 'Le code a déjà été utilisé par un autre utilisateur !',
    voucher_err_unknown: 'Le code ne peut pas être utilisé (erreur inconnue) !',
    won: 'gagné',
    wrong: 'faux',
    en: 'Anglais',
    de: 'Allemand',
    fr: 'Français',
    it: 'Italien',
    rm: 'Romanche',
    es: 'Espagnol',
    ja: 'Japonais',
    'tag_trail-loopTour': 'Circuit',
    'tag_trail-scenic': 'scénique',
    'tag_general-suitableforfamilies': 'adapté aux familles',
    'tag_general-dogFriendly': 'chiens acceptés',
    'tag_accessibility-suitableforperambulators': 'accessibles aux poussettes',
    'tag_transport-publicTransportFriendly': 'accessible en transports publics',
    'tag_general-educational': 'instructif',
    'tag_incentive-quiz': 'avec énigme',
    'tag_incentive-reward': 'avec récompense à la fin',
    'tag_general-playground': 'Aire de jeux en chemin',
    'tag_general-fireplace': 'Foyer en chemin',
    'tag_general-dining': 'Possibilité de restauration en chemin',
    'tag_general-shopping': 'Options d\'achat disponibles',
    'tag_general-publictoilet': 'Toilettes publiques disponibles',
    'tag_accessibility-wheelchair-accessible': 'accessible en chaise roulante',
  },
  it: {
    a2hs: 'Salva preferito',
    a2hs_body: 'Salva questo percorso tra i preferiti per potervi accedere rapidamente in qualsiasi momento.',
    a2hs_share_1: 'Clicca su "Condividi" in fondo al menu.',
    a2hs_share_2: 'Selezionare "Salva come preferito".',
    about: 'Su di noi',
    accept_pp: 'Accetto i <a href="{href}" target="_blank">Termini di utilizzo &amp; Privacy Policy</a>.',
    account: 'Account',
    admin: 'Admin',
    answer: 'Risposta',
    answered_correct: 'Risposta esatta',
    answered_wrong: 'Risposta errata',
    ar_howto: 'Tocca il pulsante rotondo e posiziona l\'oggetto su una superficie libera muovendo il tuo dispositivo avanti e indietro. È quindi possibile camminare intorno all\'oggetto, osservarlo da tutti i lati, spostarlo (toccando) o cambiare le sue dimensioni (gesto dello zoom).',
    authenticated_as: 'Registrato come:',
    back: 'Indietro',
    browser_hint_android: 'Per garantire una funzionalità ottimale, aprire questo percorso in Google Chrome.',
    browser_hint_ios: 'Per garantire una funzionalità ottimale, aprire questo percorso in Safari o Google Chrome.',
    browser_no_ar_android: 'Questo browser non supporta ancora completamente la realtà aumentata. Si prega di utilizzare il browser Google Chrome per sperimentare la Realtà Aumentata.',
    browser_no_ar_ios: 'Questo browser non supporta ancora completamente la realtà aumentata. Si prega di utilizzare il browser Safari o Google Chrome per sperimentare la Realtà Aumentata.',
    buy_tour: 'Acquista il trail',
    cancel: 'Interrompere',
    centerpos: 'Centrare',
    choice: 'Scelta',
    close: 'Chiudere',
    close_post: 'Chiudere il messaggio',
    continue: 'Continuare',
    continue_started: 'Continuare',
    correct_answer: 'Risposta esatta',
    created_with: 'realizzato con',
    created_with_2: 'implementato con <a href="https://tourify.ch/smartrails" target="_blank">smarTrails</a><sup>&reg;</sup> – un servizio di <a href="https://tourify.ch" target="_blank">Tourify</a>',
    email: 'E-mail',
    email_optional: 'E-mail (opzionale)',
    err_not_found: 'Non è stato trovato :(',
    err_oops: 'Oops.. Qualcosa è andato storto :(',
    err_provider_not_found: 'Non è stato trovato il provider :(',
    err_tour_not_found: 'Non è stato trovato il Tour :(',
    field_required: 'Questo campo è necessario',
    finish: 'Arrivo',
    game: 'Game',
    game_won: 'vinta',
    game_lost: 'persa',
    game_instructions: 'Istruzioni',
    game_instructions_imagepairs: 'Quali immagini appartengono allo stesso gruppo? Toccare una delle immagini elencate di seguito e poi il campo corrispondente.',
    game_instructions_falldown: 'Se l\'immagine che cade corrisponde a [SOSTITUISCI CRITERI QUI], premere il segno di credito, altrimenti premere la croce. Per ogni colpo si ottiene uno smiley, mentre ogni errore costa un cuore.',
    game_instructions_hangman: 'Toccate le lettere che pensate siano presenti nella parola che state cercando. Per ogni colpo si ottiene uno smiley, ogni errore costa un cuore.',
    game_resolution_hangman: 'Soluzione',
    games: 'Games',
    geoerr: 'Posizionamento errato',
    geoerr_1: 'Questa applicazione richiede l\'accesso alla posizione corrente. Si prega di permetterlo nelle impostazioni. Potete trovare maggiori informazioni nell\'aiuto.',
    geoerr_2: 'Posizione non disponibile',
    geoerr_3: 'Timeout della posizione',
    geohelp: 'Hai bisogno di aiuto?',
    geohelp_reach: 'Non riesco a raggiungere la postazione',
    geohelp_find: 'Non riesco a trovare la postazione.',
    geohelp_other: 'Altro motivo',
    goto_first_post: 'Vai alla prima postazione.',
    goto_next_post: 'Vai alla prossima postazione',
    goto_provider: 'Visualizza tutti i Trail',
    help: 'Aiuto',
    help_hint: 'Istruzioni di gioco',
    help_open: 'Aprire la guida',
    imprint: 'Impressum & Protezione dei dati',
    lang: 'Lingua',
    langs: 'Lingue',
    login_as_admin: 'Accedi con il tuo account di amministratore.',
    lost: 'persa',
    not_now: 'Non ora',
    number: 'numero',
    made_by: 'Un\'offerta di',
    made_by_2: 'Questa è un\'offerta di',
    map: 'Cartina',
    offline_form: 'Al momento la connessione internet è assente. Potrai inviare questo modulo non appena sarai nuovamente connesso.',
    offline_game: 'Al momento la connessione internet è assente. Potrai giocare a questo gioco non appena sarai nuovamente connesso.',
    offline_quiz: 'Al momento la connessione internet è assente. Potrai rispondere a questa domanda non appena sarai nuovamente connesso.',
    offline_smartvue: 'Al momento la connessione internet è assente. Potrai visualizzare questo oggetto non appena sarai nuovamente connesso.',
    offline_video: 'Al momento la connessione internet è assente. Potrai visualizzare il video non appena sarai nuovamente connesso.',
    open_post: 'Aprire il messaggio',
    open_post_manual: 'Aprire il messaggio manualmente',
    overview: 'Panoramica',
    payment: 'Pagamento',
    payment_success: 'Il pagamento è avvenuto con successo - Grazie mille!',
    payment_failed: 'Il pagamento non è riuscito!',
    payment_cancel: 'Il pagamento è stato interrotto!',
    post: 'Postazioni',
    post_unlocked: 'Messaggio sbloccato!',
    posts: 'Postazione',
    posts_including: 'Postazione (fino a includere)',
    progress: 'Progresso',
    progress_tour: 'Tour (postazione visitata)',
    providers: 'Fornitore',
    qrcode: 'Codice QR',
    qrcode_invalid: 'Codice QR non valevole',
    qrcode_unlock: 'Cerca e scansione il codice QR per sbloccare il contenuto di questa postazione.',
    qrhelp: 'Problemi con la scansione?',
    qrhelp_text: 'Non funziona la scansione di questo codice QR? Prova a scansionare il codice QR con il lettore integrato nella telecamera del tuo smartphone oppure con un\'applicazione per la lettura di codici QR.',
    qrscan: 'Scansionare il codice QR',
    question: 'Domanda',
    quiz: 'Quiz',
    quizzes: 'Quiz',
    rate: 'Valuta questo Trail',
    rating: 'Valutazione',
    recover: 'Sbloccare i postazione',
    recover_info: 'Inserire il codice ricevuto dalla hotline di assistenza:',
    recover_code_invalid: 'Codice non valido',
    recover_done: 'Postazione sono sbloccate',
    report_problem: 'Segnalare un problema ',
    retry: 'Ripetere',
    review: 'Osservazioni',
    reset_games: 'Resettare i games',
    reset_quizzes: 'Resettare il quiz',
    restart: 'Iniziare nuovamente',
    restart_confirm: 'Sei sicuro/a? Sarà necessario rivisitare tutte le postazioni già visitate.',
    right: 'giusto',
    roadmap: 'Cartina',
    safari_private_warning: 'Nota: se usi Safari in modalità privata, i pagamenti e altre funzioni potrebbero non funzionare.',
    satellite: 'Satellite',
    searching: 'Ricerca della posizione',
    searching_bad: 'Ha attivato I servizi di localizzazione? Potete trovare maggiori informazioni nell\'aiuto.',
    score: 'Punteggio totalizzato',
    set_all_posts_visited: 'Tutte le postazioni = visitate',
    set_all_posts_unvisited: 'Tutte le postazioni = non visitate',
    share: 'Condividere',
    sign_guest: 'accedere senza account',
    sign_in: 'Registrarsi',
    sign_in_promo: 'È raccomandato registrarsi',
    sign_in_promo_text: 'Un account personale offre svariati vantaggi.',
    sign_in_promo_learnmore: 'Saperne di più',
    sign_in_recommended: 'È raccomandato registrarsi',
    sign_in_recommended_voucher_info: 'Effettuando il login, il buono rimane memorizzato nel vostro account.',
    sign_in_required: 'Accesso necessario',
    sign_in_required_info: 'Per poter partenza il trail è necessario registrarsi.',
    sign_in_required_payment_info: 'Per poter pagare il trail è necessario registrarsi.',
    sign_in_with: 'Registrarsi con',
    sign_out: 'Logout',
    start: 'Partenza',
    submit: 'Inviare',
    support: 'Supporto',
    support_contact: 'Come contattarci',
    selfservice: 'Self-Service',
    selfservice_text: 'Sbloccare i post quando ci sono problemi e si è bloccati.',
    selfservice_unlock_posts: 'Sbloccare Postazione',
    selfservice_unlock_posts_reason: 'Si prega di spiegare brevemente il problema in modo che possiamo controllare il percorso e correggere eventuali errori.',
    tel: 'Telefono',
    thankyou: 'Grazie mille!',
    timer: 'Timer',
    timer_countup: 'Tiempo necesario',
    timer_countdown: 'Countdown',
    timer_expired: 'Tiempo expirado',
    timer_time: 'Límite de tiempo',
    tour: 'Trail',
    tour_closed: 'Questo Trail è momentaneamente chiuso.',
    tours: 'Trails',
    tries_left: 'Restano ancora {triesLeft} tentativi!',
    unauthenticated: 'Non registrato',
    unauthenticated_title: 'Attualmente stai usando l\'applicazione smarTrails senza un account personale.',
    unauthenticated_text: 'Un account personale è gratuita e offre diversi vantaggi  come per esempio la possibilità di salvare i tuoi progressi (postazioni visitate, domande risposte, ecc.) su tutti i dispositivi.',
    use_your_smartphone: 'Avvia questo percorso sul tuo smartphone e dirigiti sul sentiero.',
    visit_first: 'Recati sulla postazione per vedere il contenuto!',
    visit_posts_in_order: 'Visita le postazione nell\'ordine indicato!',
    visited: 'visitata',
    voucher_code: 'Codice buono',
    voucher_redeem: 'Riscatto buono',
    voucher_err_notfound: 'Codice non valido / non trovato!',
    voucher_err_used: 'Il codice è già stato riscattato da un altro utente!',
    voucher_err_unknown: 'Il codice non può essere riscattato (errore sconosciuto)!',
    won: 'vinta',
    wrong: 'sbagliato',
    en: 'Inglese',
    de: 'Tedesco',
    fr: 'Francese',
    it: 'Italiano',
    rm: 'Romancio',
    es: 'Spagnolo',
    ja: 'Giapponese',
    'tag_trail-loopTour': 'Circuito',
    'tag_trail-scenic': 'panoramico',
    'tag_general-suitableforfamilies': 'adatto alle famiglie',
    'tag_general-dogFriendly': 'adatto ai cani',
    'tag_accessibility-suitableforperambulators': 'adatto ai passeggini',
    'tag_transport-publicTransportFriendly': 'raggiungibile con i trasporti pubblici',
    'tag_general-educational': 'istruttivo',
    'tag_incentive-quiz': 'con indovinello',
    'tag_incentive-reward': 'con premio alla fine',
    'tag_general-playground': 'Parco giochi sulla strada',
    'tag_general-fireplace': 'Grill all\'aperto sulla strada',
    'tag_general-dining': 'punti di ristoro disponibili sulla strada',
    'tag_general-shopping': 'opzioni di acquisto disponibili',
    'tag_general-publictoilet': 'bagni pubblici disponibili',
    'tag_accessibility-wheelchair-accessible': 'accessibile con sedia a rotelle',
  }
}
