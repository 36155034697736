import store from './store'
import { fb, auth } from '@/firebase'

const initAuth = () => {
  auth.onAuthStateChanged(user => {
    if (user) {
      // user is signed in.
      store.commit('setUser', { user })
      // var displayName = user.displayName;
      // var email = user.email;
      // var emailVerified = user.emailVerified;
      // var photoURL = user.photoURL;
      // var isAnonymous = user.isAnonymous;
      // var uid = user.uid;
      // var user.isAnonymous
      // var providerData = user.providerData;
    } else {
      // user is signed out.
      // if this is the initial state, try to auth anonymously, otherwise sign out
      if (store.state.user === null) {
        auth.signInAnonymously()
      } else {
        store.commit('setUser', { user: null })
      }
    }
  })
}

const signInWithToken = async (tkn) => {
  await auth.signOut()
  await auth.setPersistence(fb.auth.Auth.Persistence.SESSION)
  return auth.signInWithCustomToken(tkn)
    .then(userCredential => {
      console.log('tkn auth success', userCredential.user.email)
      return true
    })
    .catch(err => {
      console.error('tkn auth error', err)
      return false
    })
}

export {
  initAuth,
  signInWithToken
}
