import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n'
import './plugins/googlemaps'
import './plugins/reactiveprovide'
import './registerServiceWorker'
import { initAuth, signInWithToken } from './auth'
import LogRocket from 'logrocket'
import '@/assets/styles.css'

Vue.config.productionTip = false

const LIVE = location.hostname.indexOf('test') === -1

if (LIVE && process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY)
  LogRocket.log('useragent', window.navigator.userAgent)
}

new Vue({
  store,
  router,
  vuetify,
  i18n,
  data () {
    return {
      preloads: []
    }
  },
  watch: {
    async $route ($route) {
      if ($route.query.tkn) {
        const success = await signInWithToken($route.query.tkn)
        if (success) {
          const queryUpdate = { ...$route.query }
          delete queryUpdate.tkn
          this.$router.replace({ queryUpdate })
        }
      } else {
        initAuth()
      }
    }
  },
  render: function (h) { return h(App) }
}).$mount('#app')
